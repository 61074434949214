<template>
  <div class="usersMsg">
    <div class="top clearfix">
      <p style="padding-left:15px;
          font-size:16px;
          margin-bottom: 5px;
          color: #333333;
          border-radius: 5px 5px 0 0;
          background-color: #E6ECF7;
          height: 35px; line-height: 35px;">基础信息</p>
      <table style="width: 100%; margin-left: 15px;">
        <tr>
          <td>
            <div class="pad">
              <span style="color: #333333;">用户ID：</span>
              <span>{{ userMsg.member_id ? userMsg.member_id : '' }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">手机号：</span>
              <span>{{ userMsg.mobile ? userMsg.mobile : "" }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">累计消耗积分：</span>
              <span>{{ userMsg.consum_point ? userMsg.consum_point : 0 }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">累计支付金额：</span>
              <span>{{ userMsg.sum_pay }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="pad">
              <span style="color: #333333;">姓名：</span>
              <span>{{ userMsg.member_name }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">账户余额：</span>
              <span v-if="ispetroChina">{{
                `${userMsg.enable_money}(现金余额：${userMsg.cnpc_cash}+补贴余额：${userMsg.cnpc_subsidy})` }}</span>
              <span v-else>{{ userMsg.enable_money }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">产生订单数：</span>
              <span>{{ userMsg.order_num ? userMsg.order_num : "0" }}</span>
              <i class="goorder" @click="goorder" v-if="userMsg.order_num != 0">查看订单</i>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">加入时间：</span>
              <span>{{ userMsg.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="pad">
              <span style="color: #333333;">分组：</span>
              <span>{{ userMsg.group_name || "暂无" }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">加入方式：</span>
              <span>{{ userMsg.join_way_name }}</span>
            </div>
          </td>
          <td>
            <div class="pad">
              <span style="color: #333333;">最后登录的时间：</span>
              <span>{{ userMsg.last_login | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="msgtable">
      <!-- 表单 -->
      <div class="tableList">
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="余额变动明细" name="first">
            <en-table-layout :toolbar="false" :tableData="tableData.data" @selection-change="handleSelectionChange">
              <template slot="table-columns">
                <el-table-column prop="group_name" label="变动时间" align="center">
                  <template slot-scope="scope">
                    <span v-if="ispetroChina">{{ scope.row.transaction_time }}</span>
                    <span v-else> {{ scope.row.time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="money" label="变动余额" align="center">
                  <template slot-scope="scope">
                    <span v-if="ispetroChina">{{ scope.row.is_consumption ? '-' : '+' }}{{ scope.row.transaction_amount |
                      unitPrice('￥') }}</span>
                    <span v-else>{{ scope.row.money | unitPrice('￥') }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="reason" label="变动内容" align="center">
                  <template slot-scope="scope">
                    <span v-if="ispetroChina">{{ scope.row.text_info }}</span>
                    <span v-else>{{ scope.row.reason }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" show-overflow-tooltip align="center">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.money_type === 0 || scope.row.type == 1" type="primary" size="mini"
                      @click="handleEdit(scope.$index, scope.row)">查看订单详情</el-button>
                  </template>
                </el-table-column>
              </template>
            </en-table-layout>

            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="handleSizeChange"
                  @current-change="handleCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
                  :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
                  :total="tableData.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="代金卡券变动明细" name="two">
            <en-table-layout :table-data="cardTableData.data">
              <template slot="toolbar">
                <div class="col-12">
                  <div class="row no-gutters align-items-center">
                    <el-form-item label="卡号">
                      <el-input v-model.trim="cardChangeParams.card_number" placeholder="请输入卡号" />
                    </el-form-item>
                    <el-form-item label="交易编号">
                      <el-input v-model.trim="cardChangeParams.trade_sn" placeholder="请输入交易编号" />
                    </el-form-item>
                    <el-button @click="searchCardChange" size="small" type="primary">
                      搜索
                    </el-button>
                    <div class="col"></div>
                    <el-button @click="handleExportCardChangeDetail" size="small" type="primary">
                      导出明细
                    </el-button>
                  </div>

                </div>
              </template>
              <template slot="table-columns">
                <el-table-column label="变动时间" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.create_date | unixToDate("yyyy-MM-dd hh:mm:ss") }}
                  </template>
                </el-table-column>
                <el-table-column label="变动类型" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.change_type === 0 ? '消费' : '退款' }}
                  </template>
                </el-table-column>
                <el-table-column label="变动金额" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.change_type === 0 ? `-${scope.row.amount_change}` : `+${scope.row.amount_change}` }}
                  </template>
                </el-table-column>
                <el-table-column label="变动内容" align="center" prop="reason" width="300" show-overflow-tooltip />
                <el-table-column label="代金卡名称" prop="card_name" align="center" />
                <el-table-column label="代金卡号" prop="card_number" align="center" />
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="handleDetailSizeChange"
                  @current-change="handleDetailCurrentChange" :current-page="cardTableData.page_no"
                  :page-size="cardTableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
                  :total="cardTableData.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="绑卡记录" name="three">
            <en-table-layout :toolbar="true" :tableData="bindCardList.data">
              <template slot="toolbar">
                <div style="display: flex; justify-content: space-between; width: 100%;">
                  <el-form-item label="绑卡时间">
                    <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
                      style="width: 220px;" v-model="daterangeDate" type="daterange" size="medium"
                      @change="daterangeDateChange" range-separator="-" start-placeholder="开始日期"
                      end-placeholder="结束日期"></el-date-picker>
                  </el-form-item>
                  <div style="display: flex;">
                    <el-form-item>
                      <el-input placeholder="请输入卡券名称" size="medium" prefix-icon="el-icon-search"
                        v-model="bindCardParams.card_name">
                      </el-input>
                    </el-form-item>
                    <div class="col-auto">
                      <el-button type="primary" @click="getUserChangeCard" size="small">
                        搜索
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>
              <template slot="table-columns">
                <el-table-column prop="card_name" label="卡券名称" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="card_value" label="卡券面值" align="center"></el-table-column>
                <el-table-column label="绑卡时间" align="center">
                  <template slot-scope="scope">{{ scope.row.binding_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
                </el-table-column>
                <el-table-column prop="card_code" label="卡号" align="center"></el-table-column>
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="bindHandleSizeChange"
                  @current-change="bindHandleCurrentChange" :current-page="bindCardParams.page_no"
                  :page-size="bindCardParams.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
                  :total="bindCardList.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="优惠券领取记录" name="four">
            <en-table-layout :toolbar="true" :tableData="memberKeyTableList.data">
              <template slot="toolbar">
                <div style="display: flex; justify-content: space-between; width: 100%;">
                  <el-form-item label="领取时间">
                    <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
                      style="width: 220px;" v-model="memberKeyDaterangeDate" type="daterange" size="medium"
                      @change="memberKeyDateChange" range-separator="-" start-placeholder="开始时间"
                      end-placeholder="结束时间"></el-date-picker>
                  </el-form-item>
                  <div style="display: flex;">
                    <el-form-item>
                      <el-input placeholder="请输入优惠券名称" size="medium" prefix-icon="el-icon-search"
                        v-model="memberKeyParams.coupon_name">
                      </el-input>
                    </el-form-item>
                    <div class="col-auto">
                      <el-button type="primary" @click="getUserMemberKeyCard" size="small">
                        搜索
                      </el-button>
                    </div>
                  </div>
                </div>
              </template>
              <template slot="table-columns">
                <el-table-column prop="coupon_name" label="优惠券名称" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="coupon_type" label="优惠券类型" align="center">
                  <template slot-scope="{row}">
                    {{ row.coupon_type == 1 ? '折扣卷' : '满减卷' }}
                  </template>
                </el-table-column>
                <el-table-column prop="coupon_content" label="优惠内容" align="center"></el-table-column>
                <el-table-column prop="coupon_code" label="卷码ID" align="center"></el-table-column>
                <el-table-column prop="coupon_key" label="兑换码" align="center"></el-table-column>
                <el-table-column label="领取时间" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.binding_time">{{ scope.row.binding_time | unixToDate("yyyy-MM-dd
                                          hh: mm: ss")}}</span>
                    <span v-else></span>
                  </template>
                </el-table-column>
                <el-table-column label="使用时间" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.use_time">{{ scope.row.use_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
                    <span v-else>——</span>
                  </template>
                </el-table-column>
              </template>
            </en-table-layout>
            <div class="fenye">
              <div class="block">
                <el-pagination prev-text="上一页" next-text="下一页" @size-change="memberKeySizeChange"
                  @current-change="memberKeyCurrentChange" :current-page="memberKeyParams.page_no"
                  :page-size="memberKeyParams.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
                  background :total="memberKeyTableList.data_total"></el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import * as API_Users from "@/api/users";
import * as API_member from "@/api/member";
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import { handleDownload } from "@/utils";
import { Foundation } from '@/../ui-utils'
import { getCnpcShopIds } from "@/api/account"

export default {
  name: "usersMsg",
  components: { EnTableLayout },
  data () {
    return {
      total: 0,//暂无使用该字段
      flag: false,//暂无使用该字段
      currentPage4: 4,//暂无使用该字段
      formInline: {//暂无使用该字段
        user: "",
        region: ""
      },
      multipleSelection: [],//暂无使用该字段
      activeName: "first",//tab页签默认选中字段
      inform: {//余额变动明细查询参数
        page_size: 20,
        page_no: 1,
        member_id: 0
      },
      userMsg: {},//顶部基础信息字段
      tableData: {},//余额变动明细表格数据

      cardChangeParams: {// 代金卡券变动明细查询参数
        page_no: 1,
        page_size: 20,
        member_id: this.$route.query.id
      },
      cardTableData: {//代金卡卷变动明细data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 1
      },

      bindCardList: {//绑卡记录data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      bindCardParams: {//绑卡记录查询参数
        page_no: 1,
        page_size: 20,
        card_type: 3,
        binding_member_id: this.$route.query.id
      },
      daterangeDate: '',//绑卡记录tab 时间查询字段 

      memberKeyParams: {//优惠券领取记录查询参数
        page_no: 1,
        page_size: 20,
        binding_member_id: this.$route.query.id
      },
      memberKeyDaterangeDate: '',//优惠券领取记录tab 时间查询字段
      memberKeyTableList: {//优惠券领取记录data数据
        data: [],
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      ispetroChina: false,//是否是中石油商城
    };
  },
  async created () {
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    //查询余额变动明细
    this.getmemberMsg();
  },
  methods: {
    goorder () {// 基础信息查看订单
      console.log("订单");
      this.$router.push(`/order/order-list?member_id=${this.userMsg.member_id}`);
    },
    handleClick () {
      switch (this.activeName) {
        case 'first':
          this.getmemberMsg();//余额变动明细
          break;
        case 'two':
          this.searchCardChange();//代金卡卷变动明细     
          break;
        case 'three':
          this.getUserChangeCard();//绑卡记录
          break;
        case 'four':
          this.getUserMemberKeyCard();//优惠券领取记录
      }
    },

    /**
     * @val 余额变动明细开始
     */
    handleSizeChange (val) { // 余额变动明细选择每页最大条数
      this.inform.page_size = val;
      console.log(`每页 ${val} 条`);
      this.getmemberMsg();
    },
    handleCurrentChange (val) {//余额变动明细切换页
      this.inform.page_no = val;
      console.log(`当前页: ${val}`);
      this.getmemberMsg();
    },
    async getmemberMsg () {//余额变动明细查询
      this.inform.member_id = this.$route.query.id;
      let userUrl = "memberMoney"
      if (this.ispetroChina) {
        userUrl = "transactionList"
        await API_member.cnpcMemberByMoney(this.inform.member_id)
      }
      API_member.getmembersgetInfo(this.inform.member_id).then(res => {
        this.userMsg = res;
      });

      API_Users[userUrl](this.inform).then(res => {
        if (this.ispetroChina) {
          this.tableData = {
            data: res.data.transactions,
            data_total: res.data.row_count,
            page_no: this.inform.page_no,
            page_size: this.inform.page_size
          }
        } else {
          this.tableData = res;
        }
      });
    },
    handleSelectionChange (val) {//余额变动明细复选框事件
      this.multipleSelection = val;
    },
    handleEdit (index, row) {//余额变动明细查看详情
      if (this.ispetroChina) {
        this.$router.push(`/order/order-list/detail/${row.trade_sn}`);
      } else {
        this.$router.push(`/order/order-list/detail/${row.sn}`);
      }

    },

    /**
     * @val 代金卡卷变动明细
     */
    searchCardChange () {// 代金卡卷搜索事件
      this.cardChangeParams.page_no = 1;
      if (!this.cardChangeParams.card_number) {
        delete this.cardChangeParams.card_number
      }
      if (!this.cardChangeParams.trade_sn) {
        delete this.cardChangeParams.trade_sn
      }
      this.getCardChangeList();
    },
    handleDetailSizeChange (val) {//切换分页
      this.cardChangeParams.page_size = val;
      this.getCardChangeList();
    },
    handleDetailCurrentChange (val) {//切换分页
      this.cardChangeParams.page_no = val;
      this.getCardChangeList();
    },
    getCardChangeList () {//代金卡卷查询列表
      API_member.getMemberCardChange(this.cardChangeParams).then(res => {
        this.cardTableData = res;
      })
    },
    handleExportCardChangeDetail () { // 代金卡券变动明细导出
      const headerVal = [
        '变动时间',
        '变动类型',
        '变动金额',
        '变动内容',
        '代金券名称',
        '代金券卡号'
      ]
      const contentVal = [
        'create_date',
        'changeType',
        'amount_change',
        'reason',
        'card_name',
        'card_number'
      ]
      let params = {
        ...this.cardChangeParams
      }
      params.page_size = 999999;
      API_member.getMemberCardChange(params).then(res => {
        let data = res.data.map(item => {
          item['create_date'] = Foundation.unixToDate(item.create_date);
          item['changeType'] = item.change_type == 0 ? '消费' : '退款';
          item['amount_change'] = item.change_type == 0 ? `-${item.amount_change}` : `+${item.amount_change}`;
          return item
        })
        handleDownload(data, headerVal, contentVal, "代金卡券变动记录");
      })
    },

    /**
     * @val 绑卡记录开始
     */
    bindHandleSizeChange (val) {//绑卡记录切换分页
      this.bindCardParams.page_size = val
      this.getUserChangeCard()
    },
    bindHandleCurrentChange (val) {//绑卡记录切换分页
      this.bindCardParams.page_no = val
      this.getUserChangeCard()
    },
    daterangeDateChange (val) {//绑卡记录时间格式化
      this.bindCardParams.binding_start = val ? val[0] / 1000 : '';
      this.bindCardParams.binding_end = val ? val[1] / 1000 : '';
    },
    getUserChangeCard () {//绑卡记录查询
      API_Users.getUserChangeCardList(this.bindCardParams).then(res => {
        console.log(res)
        this.bindCardList = res
      })
    },

    /**
     * @val 优惠券领取记录
     */
    getUserMemberKeyCard () {// 优惠券搜索事件
      this.memberKeyParams.page_no = 1;
      if (!this.memberKeyParams.card_number) {
        delete this.memberKeyParams.card_number
      }
      if (!this.memberKeyParams.trade_sn) {
        delete this.memberKeyParams.trade_sn
      }
      this.getUserMemberKeyList();
    },
    memberKeySizeChange (val) {//优惠券领取记录切换分页
      this.bindCardParams.page_size = val
      this.getUserMemberKeyList()
    },
    memberKeyCurrentChange (val) {//优惠券领取记录切换分页
      this.bindCardParams.page_no = val
      this.getUserMemberKeyList()
    },
    memberKeyDateChange (val) {//优惠券领取记录时间格式化
      this.memberKeyParams.min_binding_time = val ? val[0] / 1000 : '';
      this.memberKeyParams.max_binding_time = val ? val[1] / 1000 : '';
    },
    getUserMemberKeyList () {//优惠券领取记录
      API_Users.getUserMemberKeyList(this.memberKeyParams).then(res => {
        this.memberKeyTableList = res.data;
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.top {
  //border: 1px solid #666;
  color: #666666;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #fff;
  padding-bottom: 10px;
}

/deep/ .el-tabs__content {
  padding-top: 0;
}

.goorder {
  margin-left: 25px;
  color: #0099ff;
  font-style: normal;
  cursor: pointer;
}

.top .msgleft {
  float: left;
}

.top .msgright {
  float: left;
  margin-left: 130px;
}

.pad {
  padding: 4px;
}
</style>
